import { FC } from "react";
import { Box, TextField } from "@mui/material";
import { Field } from "react-final-form";
import MuiBaseAutocompleteTextFiled from "../../../components/form/fields/MuiBaseAutocompleteTextFiled";
import { Options } from "../../../components/form/fields/MuiBaseAutocompleteTextFiled/MuiBaseAutocompleteTextFiled.types";
import { Category } from "../Post.types";

type RubricTagEditFieldsProps = {
  allCategories: any;
};

const RubricTagEditFields: FC<RubricTagEditFieldsProps> = ({
  allCategories,
}) => {
  const options: Options[] = allCategories.data.map((category: Category) => {
    return {
      label: category.title,
      value: category.id,
    };
  });

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box sx={{ position: "relative" }}>
        <Field name="name">
          {({ input }) => (
            <TextField
              {...input}
              required
              id="standard-multiline-flexible"
              label="Rubric name"
              fullWidth
              multiline
              maxRows={6}
              rows={3}
              sx={{
                marginTop: "20px",
              }}
              variant="outlined"
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "16px" }}>
        <Field name="category_id">
          {({ input }) => (
            <MuiBaseAutocompleteTextFiled
              options={options}
              input={input}
              label="Category"
              isRequired
            />
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default RubricTagEditFields;
