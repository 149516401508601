import { FC, useState } from "react";
import useSWR from "swr";
import { Box, TextField } from "@mui/material";
import { Field } from "react-final-form";
import TexEditor from "../../../../components/TextEditor";
import { useFetcher } from "../../../../hooks/useApi";
import { Category, Tags } from "../../Post.types";
import UploadFileToPost from "./components/UploadFileToPost";
import UploadBlogBackground from "./components/UploadBlogBackground/UploadBlogBackground";
import BlogRubricField from "./components/BlogRubricField";
import { Options } from "../../../../components/form/fields/MuiBaseAutocompleteTextFiled/MuiBaseAutocompleteTextFiled.types";
import MuiBaseAutocompleteTextFiled from "../../../../components/form/fields/MuiBaseAutocompleteTextFiled";

type PostItemFieldsProps = {
  description: string;
  defaultCategoryId: number | null;
};

const PostItemFields: FC<PostItemFieldsProps> = ({
  description,
  defaultCategoryId,
}) => {
  const fetcher = useFetcher();
  const [selectCategoryId, setSelectCategoryId] = useState<number | null>(
    defaultCategoryId
  );
  const { data: allCategories } = useSWR("/blog/categories", fetcher);

  const selectCategory =
    selectCategoryId &&
    allCategories?.data?.find((c: any) => c.id === selectCategoryId);

  const optionsItem =
    selectCategory?.tags?.length > 0
      ? selectCategory?.tags?.map((elem: Tags) => {
          return { name: elem.tag };
        })
      : [];

  const options: Options[] = allCategories?.data?.map((category: Category) => {
    return {
      label: category.title,
      value: category.id,
    };
  });

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Field subscription={{ value: true }} name="data.seo_title">
          {({ input }) => (
            <TextField
              {...input}
              variant="standard"
              sx={{ marginRight: "40px" }}
              fullWidth
              label="Seo name"
            />
          )}
        </Field>

        <Field subscription={{ value: true }} name="data.seo_description">
          {({ input }) => (
            <TextField
              {...input}
              required
              variant="standard"
              fullWidth
              label="Seo description"
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.file">
          {({ input }) => (
            <UploadBlogBackground
              onChange={input.onChange}
              imageToken={input.value.token}
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.title">
          {({ input }) => (
            <TextField
              {...input}
              required
              variant="standard"
              fullWidth
              label="Title"
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px", display: "flex" }}>
        <Box sx={{ marginRight: "16px" }}>
          <Field name="data.category_id">
            {({ input }) => (
              <MuiBaseAutocompleteTextFiled
                options={options}
                input={input}
                label="Category"
                isRequired
                customOnChangeFunc={(newValue) => setSelectCategoryId(newValue)}
              />
            )}
          </Field>
        </Box>
        {!!selectCategoryId && <BlogRubricField optionsItem={optionsItem} />}
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <UploadFileToPost />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.description">
          {({ input }) => <TexEditor {...input} value={description || ""} />}
        </Field>
      </Box>
    </Box>
  );
};

export default PostItemFields;
