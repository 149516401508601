import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC } from "react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { FormApi } from "final-form";

import { useSnackbar } from "notistack";
import DefaultForm from "../../form/DefaultForm";
import EditRubricTagModalFooter from "./EditRubricTagModalFooter";
import { useApi } from "../../../hooks/useApi";
import RubricTagEditFields from "../../../pages/Posts/CreatePost/RubricTagEditFields";

type Props = DialogProps & {
  editData: {
    name: string;
    category_id?: number | null;
  };
  allCategories: any;
  tagId: number | null;
  onCancel: () => void;
  onUpdate: () => void;
};

type EditTagNameForm = {
  name: string;
  category_id?: number | null;
};

const EditRubricTagDialog: FC<Props> = ({
  onCancel,
  allCategories,
  editData,
  tagId,
  onUpdate,
  ...props
}) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (values: EditTagNameForm, form: FormApi) => {
    if (tagId === null) {
      try {
        await api.post(`/tags`, values);
        form.reset();
        onCancel();
        onUpdate();
        enqueueSnackbar("Rubric successful created", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } else {
      try {
        await api.put(`/tags/${tagId}`, values);
        form.reset();
        onCancel();
        onUpdate();
        enqueueSnackbar("Rubric successful updated", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    }
  };
  const initialValue = {
    ...editData,
  };

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DefaultForm<EditTagNameForm>
        data={initialValue}
        onSubmit={onSubmit}
        isValidate
      >
        <DialogTitle id="alert-dialog-title">
          {tagId === null ? "Create new rubric" : "Edit rubric"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <RubricTagEditFields allCategories={allCategories} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EditRubricTagModalFooter onCancel={onCancel} />
        </DialogActions>
      </DefaultForm>
    </Dialog>
  );
};

export default EditRubricTagDialog;
